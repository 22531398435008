<template>
	<div class="newsDetail">
		<the-nav class="newsDetail_nav">
			<svg class="svg_logo" aria-hidden="true">
				<use xlink:href="#logo-skiff-black"></use>
			</svg>
		</the-nav>

		<header>
			<div class="back" @click="$router.go(-1)">
				<svg aria-hidden="true">
					<use xlink:href="#arrowright"></use>
				</svg>
				<span>返回</span>
			</div>
			<h1 class="title">
				{{ detail.title }}
			</h1>
			<p class="time">{{ detail.date }}</p>
			<div class="tags">
				<div
					class="tags_item"
					v-for="(item, index) in detail.tags"
					:key="index"
				>
					#{{ item.tagName }}
				</div>
			</div>
		</header>

		<article v-html="detail.content"></article>

		<the-footer></the-footer>
	</div>
</template>

<script>
	export default {
		name: "news-detail",
		data() {
			return {
				detail: {}
			};
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			getInfo() {
				this.$getJSON(`/api/news/info/${this.$route.query.id}`, {
					method: "GET"
				}).then(res => {
					this.detail = res.data || {};
				});
			}
		}
	};
</script>

<style lang="scss">
	.newsDetail {
		user-select: text;
		&_nav {
			box-shadow: 0px 0.5px 0px 0px #cbcdd1;
			.theNav_end .btn {
				background: #00c699;
				color: #fff;
			}
			.theNav_end .svg_m::after {
				border-color: rgba(0, 0, 0, 0.15);
			}
			.theNav_end .svg_m svg {
				fill: #000000;
			}
		}

		header {
			padding: px2rem(24) px2rem(16);
			background-color: #f4f5f7;
			.back {
				display: flex;
				align-items: center;
				svg {
					width: px2rem(16);
					height: px2rem(16);
					fill: #000000;
					transform: rotate(180deg);
					margin-right: px2rem(4);
				}
				span {
					font-size: px2rem(16);
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: left;
					color: rgba(0, 0, 0, 0.65);
				}
			}
			.title {
				margin: px2rem(30) 0 px2rem(12) 0;
				font-size: px2rem(22);
				font-family: NotoSansSC, NotoSansSC-Medium;
				font-weight: 500;
				text-align: left;
				color: rgba(0, 0, 0, 0.85);
				word-wrap: break-word;
			}
			.time {
				margin-bottom: px2rem(8);
				font-size: px2rem(14);
				font-family: NotoSansSC, NotoSansSC-Regular;
				font-weight: 400;
				text-align: left;
				color: rgba(0, 0, 0, 0.25);
			}
			.tags {
				display: flex;
				flex-wrap: wrap;
				&_item {
					font-size: px2rem(14);
					font-family: NotoSansSC, NotoSansSC-Regular;
					font-weight: 400;
					text-align: left;
					color: rgba(0, 0, 0, 0.65);
					margin-right: px2rem(8);
				}
			}
		}

		article {
			padding: px2rem(24) px2rem(16);
			font-size: 16px;
			text-align: left;
			line-height: 2;
		}
	}
</style>
